import React from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useLazySignInQuery, setCustomer} from "../features/customer/photosSlice"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const SearchPage = (props) => {
    const { code : codeParams } = useParams();
    
    const customer = useSelector((state) => state.customer)
    const dispatch = useDispatch()


    const [redirectTo, setRedirectTo] = React.useState()
    const { register, handleSubmit, watch, setError, formState: {errors}, setValue, reset, } = useForm({
        mode: "onBlur",
        reset: {keepValues: true},
        defaultValues : {code: codeParams}
      });
    const [signingIn, setSigningIn] = React.useState(false)
    const [triggerLazySignIn] = useLazySignInQuery();
    const onSubmit = (data) => {
        setSigningIn(true)
        triggerLazySignIn({code:watch("code"), email:watch("email")})
            .then((result)=>{
                if (result.status != "rejected") {
                    //Found results for the code
                    dispatch(setCustomer(result.data))
                    setRedirectTo(`/photos`) ///${data.code}
                    console.log(result)
                } else {
                    //Code Doesn't Exist
                    console.log(result)
                    if (result.error.status == 404){
                        setError('code', { type: 'invalidCodeSearch', message: 'Images for this code could not be found. Please check your code and try again in a few moments.' });
                    }
                }
                setSigningIn(false)
                
            })
            .catch(err=>{
                alert('An error occured, please try again.', err)
                setSigningIn(false)
            })
        
    }
    
    
    

    const codeOnChange = (e=>{
        let formattedCode = e.target.value
        formattedCode = formattedCode.toUpperCase()
        formattedCode = formattedCode.replace(' ', '')
        formattedCode = formattedCode.replace('-', '')
        formattedCode = formattedCode.replace('_', '')
        setValue("code",formattedCode)
    })


    return <div className="landingForm">
        {redirectTo && <Navigate to={redirectTo}/>}
        <p>
            Please enter your details below to access your photos.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
            <input placeholder="Code" defaultValue="" {...register("code", {required: true, onChange:codeOnChange, pattern: /[A-Z0-9]{3}[0-9]{2}[A-Z0-9]{5}$/g}) } aria-invalid={errors.code ? "true" : "false"}/>
            {errors.code?.type === 'required' && <p className="error" role="alert">Code is required</p>}
            {errors.code?.type === 'pattern' && <p className="error" role="alert">Code is invalid</p>}
            {errors.code?.type === 'invalidCodeSearch' && <p className="error" role="alert">{errors.code?.message}</p>}
            <input placeholder="Email Address" defaultValue="" {...register("email", {required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i})} aria-invalid={errors.email ? "true" : "false"}/>
            {errors.email?.type === 'required' && <p className="error" role="alert">Email is required</p>}
            {errors.email?.type === 'pattern' && <p className="error" role="alert">Email is invalid</p>}
            {signingIn ? <div className={"loading-spinner"}>
                    <FontAwesomeIcon icon={faCircleNotch} spin/>
            </div> : <button type="submit">Go to my photos</button>}
            
        </form>
        
    </div>
}

export default SearchPage
